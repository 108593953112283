import React, { useState } from 'react';
import './styles.scss';

const Terms = () => {
	const termsText = (
		<p className="terms-text">
			<strong> Aspectos generales</strong> <br />
			PLURIZA S.A.S., es una sociedad constituida el 20 de junio de 2017 e inscrita a la cámara de comercio de barranquilla el día 21 de
			junio de 2017 en el número 328.137 del libro IX, identificada con NIT No. 901.090.795 – 1, con domicilio en la ciudad de barranquilla,
			quien para efectos de los presentes términos y condiciones se denominará Estori. <br />
			Los términos y condiciones enunciados en el presente documento regulan la relación entre usted de ahora en adelante (“usuario”,
			“usuarios”, “usted”) y Estori de ahora en adelante (“plataforma”, “aplicación móvil”, “servicio de información” “nosotros”), de igual
			manera regularan el uso que usted haga de nuestra plataforma. <br />
			<br /> Condiciones generales de uso. <br />
			<br /> • La plataforma Estori es propiedad de PLURIZA S.A.S. <br />
			• Cuando el usuario interactúa, accede o descarga el servicio de información de Estori a través de cualquier herramienta tecnológica
			acepta que se ha informado de manera suficiente de los términos y condiciones aquí enunciados y que se encuentra de acuerdo con ellos.{' '}
			<br />
			• El usuario acepta que es mayor de edad y cuenta con capacidad legal para aceptar los términos y condiciones. <br />
			<br /> Definiciones <br />
			<br /> • Plataforma “Estori”: Hace referencia a nuestra plataforma web o móvil que funciona bajo la modalidad de pago por uso. <br />
			• Usuario: Se refiere a la persona, empresa o plataforma que directa o indirectamente interactúe, acceda o tenga cualquier tipo de
			relación con “Estori”. <br />
			• Cuenta: Es el espacio que se le otorga a cada usuario dentro de la plataforma Estori donde puede utilizar todas las funcionalidades
			del software luego de realizar el pago de la mensualidad y la implementación. <br />
			• Servicio: Son las actividades que, ofrecidas por los <strong> usuarios prestadores del servicio</strong> , las cuales se dividen por
			categorías. <br />
			• Pago por uso Estori: Es el pago mensual que debe realizar el usuario para utilizar la plataforma Estori y que corresponde a un valor
			de $50.000 pesos mensuales. <br />
			• Saldo: Es el saldo total en dinero disponible que tienen: 1) Los <strong> usuarios prestadores del servicio </strong> por los
			productos vendidos al utilizar los servicios de información prestados por Estori y 2) Los{' '}
			<strong> usuarios tomadores del servicio</strong> por las devoluciones a su favor o promociones. <br />
			<br /> • Apoyo en la gestión de pagos e información de pagos: Es el servicio de información de pago y gestión de pagos que presta
			Estori a los <strong> usuarios prestadores del servicio</strong> para que puedan recibir los pagos que reciben por prestar servicios
			de forma autónoma e independiente. <br />
			<br /> Servicios <br />
			<br /> Estori no es responsable por los productos o servicios o las actividades que realice el usuario a través del uso de su
			software. Cualquier actividad ejercida por los usuarios utilizando el software de Estori será responsabilidad del usuario. En caso de
			que se presenten situaciones que afecten derechos de terceros o que sean ilegales, Estori podrá suspender el servicio de poner a
			disposición su software. <br />
			Por último, el software también permite gestionar los pagos a través del uso de este por parte de los usuarios. <br />
			• Deberes de los usuarios: Son deberes de los usuarios: <br />
			Pagar el valor mensual por el uso del software de Estori. <br />
			Pagar el valor de la implementación para el uso del software de Estori. <br />
			Hacer un adecuado uso del software de Estori cumpliendo con las leyes nacionales y municipales. <br />
			Cumplir con todas las normas y procedimientos relacionados con la protección de datos personales. <br />
			Obligaciones de los usuarios: <br />
			<br /> Al utilizar Estori usted como usuario acepta que: <br />
			• Es persona natural, mayor de edad o persona jurídica que cuenta con capacidad legal para aceptar estos términos y condiciones.{' '}
			<br />
			• Cumplir los términos y condiciones específicos de cada tipo de usuario <br />
			• Reconoce que ha entregado datos personales a nuestra empresa y acepta nuestras políticas de tratamiento de datos. <br />
			• El usuario de Estori acepta no anunciar, enviar por email ni de cualquier otra forma poner a disposición contenido que: - Sea
			contrario a la Ley. – Viole una patente, marca registrada, secreto comercial, derecho de autor u otro derecho de propiedad de
			cualquier persona, o Contenido respecto del cual el Usuario no tiene derecho a poner a disposición del público conforme a una
			disposición legal, una relación contractual o fiduciaria. – Sea dañino, ilícito, amenazador, abusivo, acosador, difamatorio,
			pedofilia, que invada la privacidad de otros o que dañe a menores de cualquier forma. – Acose, degrade, intimide o sea odioso respecto
			a un individuo o un grupo de individuos por su religión, género, orientación sexual, raza etnia, edad o discapacidad. – Imite a otra
			persona o entidad, incluyendo un empleado, representante o agente de nuestra aplicación móvil, o que implique una falsa declaración o
			que de cualquier manera tergiverse la relación del Usuario con una persona o con una entidad. – Incluya información personal o de
			identificación de otra persona, sin el consentimiento expreso de la persona mencionada. – Sea falso, engañoso, de una falsa
			apariencia, tramposo, engañe respecto de la información o que constituya una forma de “atracción y luego se modifique lo ofrecido”. –
			Constituya o contiene “marketing afiliado”, un “código de referencia a enlace”, “mail basura”, “spam”, “cadenas de cartas”, “esquemas
			de pirámides” o publicidad comercial no solicitada. – Publicita servicios ilícitos o la venta de artículos cuya venta se encuentra
			prohibida o restringida por la Ley aplicable según el caso. – Contiene virus de software u otros códigos, archivos o programas de
			computación diseñados para interrumpir, destruir o limitar la funcionalidad de un software o de un hardware de computación o de un
			equipo de telecomunicaciones. – Interrumpa el flujo normal del diálogo con una cantidad excesiva de mensajes (ataque masivo) a la
			plataforma, que de alguna forma afecta en forma negativa la capacidad de los Usuarios para utilizar la aplicación móvil. <br />
			• Los diseños, funcionalidades y demás componentes del software Estori están protegidos por derechos de autor y son propiedad de la
			empresa PLURIZA S.A.S., por lo tanto no se permite su reproducción, copia o cualquier otra utilización por fuera de los parámetros
			técnicos establecidos por el software sin previa autorización de PLURIZA S.A.S. <br />
			Estos términos y condiciones podrán ser modificados por la aplicación móvil “Estori” en cualquier momento, sin que eso genere ningún
			tipo de responsabilidad. <br />
			<br /> <strong> Aceptación total de los presentes términos y condiciones </strong> <br />
			Una vez un usuario ingrese a la plataforma o interactúe a través cualquier medio acepta en su totalidad los presentes términos y
			condiciones vigentes, y cualquier modificación de los mismos, así como los derechos y deberes de acuerdo a su rol establecido. <br />
			<br />
			<strong> Política de privacidad y tratamiento de datos personales </strong> <br />
			Estori (como se conocerá en adelante), crea la siguiente política de privacidad y tratamiento de los datos que sean recolectados
			provenientes de personas, tanto naturales como jurídicas durante y después de la prestación del servicio de información prestado, con
			fines estrictamente relacionados con aspectos comerciales, garantizando el derecho de todos los intervinientes a conocer, actualizar y
			rectificar las informaciones que sobre ellos se hubiere recogido. <br />
			<br /> <strong> Política de tratamiento de datos </strong> <br />
			Responsable del tratamiento de los datos: <br />
			<strong> Nombre: </strong>PLURIZA S.A.S. <br />
			<strong> Teléfono:</strong> +57 300 206 1607 <br />
			<strong> Correo electrónico:</strong> hello@pluriza.com <br />
			Encargado del tratamiento de datos: <br />
			PLURIZA S.A.S., se encargará de la recolección, almacenamiento, manejo, trasmisión, rectificación, actualización y protección de los
			datos provenientes de los usuarios de la plataforma, así como de terceros. <br />
			<br />
			<strong> Recolección de la información</strong> <br />
			PLURIZA S.A.S. recibe y almacena información sobre usted, la cual incluye: <br />
			La información que usted nos entrega: Compilamos información que usted nos entrega en diferentes momentos, como lo son: <br />
			► Al momento de interactuar a través de la aplicación móvil, de la plataforma web o de cualquier otro medio de contacto establecido
			con Estori, se solicitarán datos para poder prestar el servicio ofrecido por la plataforma en los siguientes puntos de interacción:{' '}
			<br />
			► Al momento en que el usuario use el software de Estori realizando todas las funcionalidades ofrecidas para prestar sus servicios.{' '}
			<br />
			►Cuando existe interacción con nuestro servicio al cliente. <br />
			►Cuando usted presenta solicitudes, quejas o reclamos ante Estori y le solicitamos datos para poder solucionar su inconformidad.{' '}
			<br />
			►Cuando usted participa en encuestas o promociones de marketing. <br />
			►Cuando usted nos proporciona reseñas, calificaciones o preferencias. <br />
			►Cuando la plataforma necesite información adicional para uso del servicio y la solicite directamente. <br />
			►Información compilada automáticamente: Recopilamos información relacionada con usted, el uso de nuestro servicio, sus interacciones
			con nosotros y la publicidad, igualmente de aquella información relacionada con el dispositivo que usted utiliza para acceder a
			nuestro servicio. Algunos ejemplos de lo que incluye esta información son: <br />
			ID de dispositivos u otros identificadores únicos, características de software y dispositivo, información de conexión, estadísticas
			sobre visualización de las páginas, URL de referencias, direcciones IP, Su ubicación para prestarle un mejor servicio. <br />
			La información compilada a través del uso de cookies, señalizaciones web y otras tecnologías, incluidos datos de publicidad
			(información sobre la disponibilidad y entrega de anuncios, el URL del sitio, así como la fecha y horas). <br />
			<br /> ►Información de otras fuentes: También complementamos información anterior con información de otras fuentes, como proveedores
			de datos obtenidos tanto online como sin offline. La información puede incluir datos demográficos, datos basados en los intereses y
			conductas de navegación por internet. <br />
			<br /> <strong> ¿Cómo usamos tu información? </strong> <br />
			• Usamos la información compilada para procesar su registro, solicitudes, analizar, administrar, mejorar y personalizar nuestros
			servicios e iniciativas de marketing, para comunicarnos con usted y para prestarle un servicio cada vez mejor, por ejemplo, la
			utilizamos para lo siguiente: <br /> • Determinar la ubicación geográfica donde se encuentra, con el fin de acceder y/o prestar los
			servicios de uso óptimo del software, ofrecerle contenidos, brindarle recomendaciones, determinar su proveedor de servicio de
			internet, ofrecerle información comercial, responder con mayor eficiencia a las consultas y solicitudes, y demás que sean necesarias
			para prestarle un mejor servicio de información.
			<br />• Compartirla con terceros para mejorar la experiencia de uso del software.
			<br />• Detectar, prevenir e investigar actividades prohibidas, como el fraude, hacer cumplir nuestros términos.
			<br />
			• Analizar y comprender a nuestros usuarios, mejorar nuestro servicio y optimizar la selección de los contenidos, los algoritmos de
			recomendación y la entrega. <br />
			• Comunicarnos con usted de acuerdo a nuestro servicio (notificaciones, email, mensajes de texto y mensajería online), con el fin de
			enviarle noticias sobre la plataforma Estori, nuevas funciones y también ofertas que mejoren su experiencia. También para solucionar
			temas operativos como problemas técnicos. <br />
			<br /> <strong> Transferencia de información </strong> <br />
			Transferimos su información a terceros con el fin de mejorar su experiencia como usuario y que usted acceda a promociones y beneficios
			efectivos relacionados con el servicio. <br />
			<br />
			<strong> Tratamiento específico</strong> <br />
			Los datos que sean aportados por los usuarios de la plataforma y demás terceros, serán sujetos a recolección, almacenamiento, uso,
			transferencia, transmisión, supresión o suspensión, modificación, actualización, observando en todo lugar, los principios de Seguridad
			y confidencialidad, así como también, el deber de informar a todos aquellos que hayan aportado datos, sobre cualquier alteración que
			involucre dicha información. <br />
			En vista de lo anterior, solo las personas que demuestren estar autorizadas por parte de los titulares de la información ya sea para
			su retiro, modificación o alteración de cualquier tipo, se les permitirá realizar dichas actividades, aquellos que no se les negara
			esta posibilidad, éstas, deberán expresarse en forma escrita por parte del titular de la información describiendo claramente su deseo
			frente al manejo de sus datos. <br />
			Los datos serán utilizados con las siguientes finalidades entre otras: <br />
			Recolectar en la base de datos de Estori información necesaria para el ingreso a la plataforma de los usuarios. <br />
			Almacenar dicha información en las bases de datos de Estori. <br />
			Estudiar dicha información con fines de mejorar los servicios de información que disponga Estori. <br />
			Suspender, sustituir, eliminar y modificar los datos inscritos contando con el consentimiento informado y previo del titular de los
			mismos en concordancia con los términos y condiciones. <br />
			Enviar información comercial a los usuarios de Estori. <br />
			Manejo responsable de los datos que ingresen en la base de datos de Estori. <br />
			<br /> Analizar el comportamiento de los usuarios con el propósito de mejorar aspectos estructurales de Estori. <br />
			Transferir datos entre los usuarios de la plataforma con el fin de mejorar la experiencia de usuario y mejorar componentes técnicos y
			de uso del software. <br />
			Prestar los servicios relacionados con la naturaleza de Estori. <br />
			<br />
			<strong> Quejas, reclamos y solicitudes </strong> <br />
			Estori consciente de su compromiso con los datos que son aportados por todos los usuarios serán atendidos, escribiendo al correo
			electrónico: hello@pluriza.com <br />
			<br /> <strong> Vigencia </strong> <br />
			La presente política de datos comenzará a regir a partir de su publicación en la plataforma de Estori y podrá ser modificada en
			cualquier momento por la plataforma. <br />
			<br />
			<strong> Pasarela de pagos</strong> <br />
			Pluriza S.A.S. utiliza la pasarela de pago de CrediBanco. Para consultar su política de protección y tratamiento de datos personales,
			por favor diríjase al siguiente link. <br />
			<a href="https://www.credibanco.com/sites/default/files/politica_de_proteccion_y_tratamiento_de_datos_personales.pdf" target="_blank">
				Credibanco Tratamiento de Datos Personales.
			</a>
			<br />
		</p>
	);
	return (
		<div className="terms-container">
			<div className="terms-title">Términos y condiciones de uso del servicio y plataforma Estori.</div>

			<div className="terms-text-container">{termsText}</div>
		</div>
	);
};

export default Terms;
